.nav--menu--cont {
    display: flex;
    background-color: black;
    color: white;
    z-index: 100;
    position: absolute;
    left: 0;
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .nav--menu--cont {
        bottom: 0;
        width: 100%;
        height: 100px;
        display: flex;
    }
    .mobile--nav--menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 98%;
        /* background-color: rgba(143, 143, 19, 0.465); */
        margin: auto;
    }
    .mobile--nav--links {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        /* gap: 15px !important; */
        width: inherit;
        /* background-color: rgba(255, 0, 0, 0.144); */
    }
.mobile--nav--links > a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /* gap: 15px !important; */
        width: inherit;
        /* font-size: 20px; */
        text-transform: uppercase;
        cursor: pointer;
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        opacity: 0.7;
        color: var(--font);
        font-weight: 400;
        transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
    }

    .mobile--nav--links > a:hover {
        color: var(--tertiary);
        opacity: 1;
        text-decoration: underline;
        transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
    }
    .nav--menu--icon--mobile{
        font-size: 30px;
        margin: none;
    }
    .nav--user {
        /* height: 100px; */
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: relative;
    }
}

/* Desktop */
@media only screen and (min-width:480.1px) {
    .nav--menu--cont {
        top: 0;
        height: 100vh;
        /* overflow: hidden; */
    }

    .desktop--nav--menu {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .closed--nav {
        padding-top: 100px;
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0px);
        width: 70px;
        transition: transform 0.5s ease-in-out, opacity 0.1s ease, width 0.1s ease;
    }

    .open--nav {
        padding-top: 100px;
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0px);
        width: 250px;
        transition: transform 0.5s ease-in-out, opacity 0.1s ease, width 0.1s ease;

    }

    .desktop--nav {
        display: flex;
        align-items: center;
        padding-top: 200px;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;
    }

    .desktop--nav--links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px !important;
        width: inherit;
    }

    .nav--user--cont {}

    .nav--user {
        height: 150px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .nav--user--type {
        margin: 0;
        margin-top: 5px;
        opacity: 0.5;
        font-weight: 400;
        font-size: 15px
    }

    .nav--user--name {
        margin: 0;
        font-size: 20px;
    }

    .desktop--nav--links > a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 15px !important;
        width: inherit;
        font-size: 20px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        opacity: 0.7;
        color: var(--font);
        font-weight: 500;
        transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
    }

    .desktop--nav--links > a:hover {
        color: var(--tertiary);
        opacity: 1;
        text-decoration: underline;
        transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
    }

    .nav--menu--icon {}
}