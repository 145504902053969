/* <AppPageContainer> */
.app--page--container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    padding-top: 250px;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: padding 0.1s ease;
    box-sizing: border-box;
    /* flex-direction: column;
    flex-wrap: wrap; */

}
@media only screen and (max-width: 480px) {
    .openSidebar{
        /* background-color: yellow; */
        padding-left: 0;
        padding-bottom: 100px;
    }
    .closedSidebar{
        /* background-color: rebeccapurple; */
        padding-left: 0;
        padding-bottom: 100px;
    }
}
@media only screen and (min-width:480.1px){
    .openSidebar{
        /* background-color: yellow; */
        padding-left: 250px;
    }
    .closedSidebar{
        /* background-color: rebeccapurple; */
        padding-left: 70px;
    }
}

/* </AppPageContainer> */

/* <AppContentWrapper> */
.app--page--wrapper{
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-attachment: fixed;
    width: inherit;
    overflow: hidden;
    position: relative;
    transform: translateY(-125px);
    flex-wrap: wrap;
}
/* </AppContentWrapper> */

/* <PageContainer> */
.page--container{
    width: 100%;
    position: absolute;
    /* background-color: rgba(255, 228, 196, 0.342); */
    /* margin-left: 10px; */
    height: 90vh;
    top: 0;
    color: var(--font);
    padding-top: 125px;  
    /* overflow-y: scroll; */
}
/* </PageContainer> */

/* <CollectionPageContainer> */
.collection--page--container--component{
    width: 100%;
    position: absolute;
    /* background-color: rgba(255, 228, 196, 0.342); */
    /* margin-left: 10px; */
    height: 90vh;
    top: 0;
    color: var(--font);
    overflow-y: scroll;
    padding-top: 125px;
      
  }
.wrapOpenSidebar{
    /* background-color: rgba(0, 255, 47, 0.432); */

}
.wrapClosedSidebar{
    /* background-color: rgba(51, 153, 143, 0.192); */
    
}
/* </CollectionPageContainer> */


.search--results--cont{
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 76vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    /* background-color: rgb(255, 0, 0); */
    padding-bottom: 40px;
    box-sizing: border-box;
}
.app--title--cont{
    width: 100%;
    min-height: 50px;
    max-height: 100px;
    position: sticky;
    top:0;
    /* background-color: rebeccapurple; */
    color:var(--font);
    text-transform: uppercase;
    padding-left: 15px;
}
.app--title--cont h1{
    font-size: 45px;
    font-weight: bold;
}
.app--title--cont h2{
    font-size: 35px;
    font-weight: bold;
}
.app--title--cont h3{
    font-size: 25px;
    font-weight: bold;
}


.content--title--cont{
    width: 60%;
    min-height: 30px;
    max-height: 70px;
    position: sticky;
    top:0;
    background-color: rgba(102, 51, 153, 0);

    opacity: 0.8;
    color:var(--font);
    text-transform: uppercase;
    padding-left: 15px;
    margin-left: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--font);
    margin-top: 10px;
}
.content--title--cont h1{
    font-size: 30px;
    font-weight: bold;
}
.content--title--cont h2{
    font-size: 25px;
    font-weight: bold;
    transform: translateY(-12px);
}
.content--title--cont h3{
    font-size: 20px;
    font-weight: bold;
    transform: translateY(-25px);
}


.asset--content--container{
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    /* overflow-y: scroll; */
    
}