@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
:root {
  --bg: #1d1d1d;
  --bg--shadow: rgba(78, 78, 78, 0.314);
  --font: #ffffff;
  --font--shadow: #ffffff55;
  --primary: #a30000;
  --primary--shadow: #a300006d;
  --secondary: #291414;
  --secondary--shadow: #29141480;
  --tertiary: #ff859f;
  --tertiary--shadow: #ff85a03b;
  --highlight--1: #93a3bc;

  --font--fam: 'Poppins', sans-serif; 
  --header--font: 'Hind', sans-serif;
}
/* --text: #ffffff;
--background: #2b2b2b;
--primary: #a30000;
--secondary: #291414;
--accent: #ff859f; */



/* 'Didact Gothic', sans-serif; */
/* --bg: #ffffff;
  --font: #000000;
  --primary: #48acf0;
  --primary--shadow: #48adf0a1;
  --secondary: #594236;
  --tertiary: #cd1c10;
  --highlight--1: #93a3bc;

  --font--fam: 'Poppins', sans-serif; */
/* --bg: #ccdde2; */
h1 {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 50px;
  font-weight: 900;
  margin: 3vh;
  color: var(--tertiary);
}

h2 {
  position: relative;
  color: var(--font);
  font-family: 'Hind', sans-serif;
  margin: 1vh;
  
}

h4 {
  position: relative;
  font-family: 'Poppins', sans-serif;
  opacity: 0.8;
  font-size: 14px;
  margin: 0.5vh;
  color: var(--font);
  
}

p {
  position: relative;
  color: var(--font);
  font-size: 24px;
  margin: 0;
  font-family: 'Didact Gothic', sans-serif;
}
 span{
  margin: 0;
 }
 div{
  margin: 0;
  /* padding: 0; */
 }
 
 button:disabled,
 button[disabled]{
   border: 1px solid #999999;
   background-color: #cccccc;
   color: #666666;
   opacity: 0.3;
   cursor:not-allowed;
 }
 

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.FL--App--Cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  /* min-height: 500px; */
  height: 100vh;
  background-color: var(--bg);
}

.gradient-cont {
  width: 100%;
  /* background: rgb(193, 99, 99); */
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--bg));
  position: fixed;
  top: 0;

  z-index: 900;
}

.FL--Logo--Cont {
  position: absolute;
  top: 0%;
  left: 0%;
  width: fit-content;
  /* background-color: tan; */
  z-index: 900;
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.FL--Menu--Cont {}

.FL--Search--Cont {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100vw;

  z-index: 899;
  background-color: #2163ce00;
  display: flex;
  align-items: center;
  justify-content: center;

}

.mar--lr {
  margin-left: 25px;
  margin-right: 25px;
}

.mar--l {
  margin-left: 25px;
}

.mar--r {
  margin-right: 25px;
}

.header--height {
  height: 125px;
}

.bottom--gradient-cont {
  width: 100%;
  /* background: rgb(193, 99, 99); */
  background: linear-gradient(180deg, 
  rgba(41, 41, 41, 0) 0%, 

  var(--bg) 100%);
  position: fixed;
  bottom: 0;
  height: 20px;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: end;

}

.bottom--gradient-cont h4 {
  font-size: 10px;
  transform: translate(-10px, 5px);
  margin-right: 15px;
  cursor: default;
}


.FL_Input__text__1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: (--font--fam);
  overflow: hidden;
  padding-bottom: 5px;
}

.FL_Input__text__1 label {
  color: var(--font);
  font-size: 18px;
  font-weight: 600;
}

.FL_Input__text__1 input {
  width: 633px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 500px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  color: var(--font);
}

.FL_Input__text__1 h4 {
  color: var(--font);
  font-size: 13px;
  font-weight: 600;
  transform: none;
  text-transform: none;
  margin: 0;
}



.FL_Input__text__1 textarea {
  width: 633px;
  resize:none;
  height: 57px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius:  25px 2px 2px 25px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-family: var(--font--fam);
  padding-top: 5px;
  font-size: 18px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 15px ;
  color: var(--font);
  /* margin-right: 30px; */
}
.FL_Input__text__2 textarea {
  width: 633px;
  resize:none;
  height: 300px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius:  25px 2px 2px 25px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-family: var(--font--fam);
  padding-top: 5px;
  font-size: 18px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 15px ;
  color: var(--font);
  /* margin-right: 30px; */
}
.FL_Input__text__2 h4 {
  color: var(--font);
  font-size: 20px !important;
  font-weight: 600;
  transform: none;
  text-transform: none;
  margin: 0;
}
.FL_Input__text__2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: (--font--fam);
  overflow: hidden;
  padding-bottom: 5px;
}

.FL_Input__text__2 label {
  color: 000000;
  font-size: 18px;
  font-weight: 600;
}

.FL_Input__text__2 input {
  width: 285px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 500px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  color: var(--font);
}

.FL_Input__text__2 h4 {
  color: var(--font);
  font-size: 13px;
  font-weight: 600;
  transform: none;
  text-transform: none;
  margin: 0;
}
.FL_Input__number__1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: (--font--fam);
}

.FL_Input__number__1 label {
  color: var(--font);
  font-size: 18px;
  font-weight: 600;
}

.FL_Input__number__1 .inner--cont {
  display: flex;
  align-items: center;
  color: var(--font);
}

.FL_Input__number__1 .inner--cont div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  width: 50px;
  height: 47px;
}

.FL_Input__number__1 .inner--cont div h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 400;
  color: var(--font);
}

.FL_Input__number__1 .inner--cont div h4 {
  margin: 0;
  font-size: 15px;
  transform: none;
  color: var(--font);
}

.FL_Input__number__1 .inner--cont input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 132px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 500px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 24px;
  color: var(--font);
}

.FL_Input__date__1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: (--font--fam);
  color: var(--font);

}

.FL_Input__date__1 label {
  color: 000000;
  font-size: 18px;
  font-weight: 600;
  color: var(--font);
}

.FL_Input__date__1 input {
  width: 331px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 500px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--font);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #ffffff24;
  margin: 0;
  font: inherit;
  color: red;
  width: 46px;
  height: 46px;
  border: 0.15em solid red;
  border-radius: 6px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.form-control + .form-control {
  margin-top: 1em;
}
input[type="checkbox"]::before {
  content: "";
  width: 24px;
  height: 24px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em rgb(255, 255, 255);
  /* background-color: CanvasText; */

transform-origin: center;
clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);

}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:checked {
  background-color: red;
}
input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid black;
  outline-offset: max(2px, 0.15em);
}
input[type="checkbox"]:disabled {
  --form-control-color: grey ;
opacity: 0.3;
  color: grey ;
  cursor: not-allowed;
}

.FL_btn__1{
  background-color: rgb(27, 27, 27);
  border: none;
  color: white;
  min-width: 300px;
  height: 50px;
  border-radius: 50px;
  font-family: var(--font--fam);
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
  opacity: 1;
  transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease, opacity 0.5s ease;
 
}
.FL_btn__1:disabled{
opacity: 0.5;
transition: opacity 1s ease;
}

.FL_btn__1:hover{
  transform: scale(1.01); /* Increase the scale on hover */
  box-shadow: rgba(84, 84, 84, 0.845) 0px 1px 2px;
  text-shadow: rgba(212, 210, 210, 0.533) 0px 1px 2px;
}
.FL_btn__2{
  background-color: var(--font);
  border: none;
  color: var(--bg);
  min-width: 300px;
  height: 50px;
  border-radius: 50px;
  font-family: var(--font--fam);
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
  opacity: 1;
  transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease, opacity 0.5s ease;
 
}
.FL_btn__2:disabled{
opacity: 0.5;
transition: opacity 1s ease;
}

.FL_btn__2:hover{
  transform: scale(1.01); /* Increase the scale on hover */
  box-shadow: rgba(84, 84, 84, 0.845) 0px 1px 2px;
  text-shadow: rgba(212, 210, 210, 0.533) 0px 1px 2px;
}
.active {
  transform: scale(0.5); /* Decrease the scale when active */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--primary--shadow) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--secondary--shadow);
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary--shadow);
  border-radius: 8px;
  border: 2px solid var(--secondary--shadow);
  /* width: 80%; */
}

